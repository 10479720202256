export const translation = {
  header: {
    items: [
      {
        label: "Start",
        href: "https://www.wilms.eu/",
        newTab: true,
      },
      {
        label: "Fachgebiet",
        href: "https://www.wilms.eu/fachgebiete/",
        newTab: true,
      },
      {
        label: "Rechtsanwälte",
        href: "https://www.wilms.eu/rechtsanwaelte/",
        newTab: true,
      },
      {
        label: "Kanzlei",
        href: "https://www.wilms.eu/kanzlei/",
        newTab: true,
      },
      {
        label: "Karriere",
        href: "https://karriere.wilms.eu",
        newTab: true,
      },
      {
        label: "Kontakt",
        href: "https://www.wilms.eu/kontakt/",
        newTab: true,
      },
      {
        label: "Blog",
        href: "https://www.wilms.eu/blog/",
        newTab: true,
      },
    ],
  },

  home: {
    hero: {
      subtitle: "Karriere",
      title: ["Viele Menschen", "mit einem Ziel"],
      subline: "Werden Sie Teil von Wilms Rechtsanwälte",
    },
  },

  footer: {
    name: "WILMS und Partner mbB",
    street: "Alt-Tempelhof 37/41",
    city: "12103 Berlin",
    openHours: "Montag - Freitag 8.00 - 17.00 Uhr",
    phonePrefix: "T ",
    phone: "+49 30 75 79 64 44",
    faxPrefix: "F ",
    fax: "+49 30 75 79 64 48",
    homepage: "www.wilms.eu",
    description:
      "Damit wir gemeinsam mit Ihnen die beste Lösung für Sie finden können, nehmen Sie bitte Kontakt mit uns auf und vereinbaren einen persönlichen Beratungstermin.",
  },

  imprint: {
    headline: "Impressum",
  },

  dataProtection: {
    headline: "Datenschutz",
  },

  gtc: {
    headline: "AGB",
  },

  generic: {
    firstname: "Vorname",
    lastname: "Nachname",
    mail: "Email",
    phone: "Telefon",
    password: "Passwort",
    imap: {
      server: "IMAP-Server",
      port: "IMAP-Port",
    },
    smtp: {
      server: "SMTP-Server",
      port: "SMTP-Port",
    },
    company: {
      name: "Firmenname",
      taxNumber: "USt-IdNr.",
      street: "Straße",
      postcode: "Postleitzahl",
      city: "Stadt",
      phone: "Telefon",
      website: "Webseite",
    },
  },

  login: {
    headline: "Anmelden",
    cta: "anmelden",
    error: "E-Mail Adresse oder Passwort falsch.",
    switchToSignup: "Noch kein Account? Hier registrieren.",
  },
  signUp: {
    headline: "Registrieren",
    cta: "registrieren",
    error: "Ihr Account konnte nicht registriert werden.",
    switchToLogin: "Bereits registriert? Hier einloggen.",
    personalDetail: "Persönliche Daten",
    companyDetail: "Firmendaten",
    successMessage:
      "Ihr Account wurde erfolgreich registriert. Sie erhalten in Kürze eine E-Mail mit einem Bestätigungslink.",
    activation: {
      headline: "Account aktivieren",
      success:
        "Ihr Account wurde erfolgreich aktiviert. Sie können sich nun einloggen.",
      error: "Ihr Account konnte nicht aktiviert werden.",
      switchToLogin: "Jetzt einloggen.",
    },
  },

  UI: {
    dropdown: {
      emptySelection: "Bitte wählen",
    },
  },
};

export const langKey = "DE";
