import styled from "styled-components";
import hero from "../../../assets/image/home/hero/background.webp";

export const StyledHero = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${hero});
  background-size: cover;
  background-position: center center;
`;
