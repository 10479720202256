import styled from "styled-components";

export const StyledLayout = styled.div``;

export const CoreContent = styled.div<{ noPaddingTop?: boolean }>`
  background-color: ${({ theme }) => theme.color.background.primary};
  min-height: calc(100vh - ${({ theme }) => theme.header.height} - 50px);
  padding-top: ${({ noPaddingTop, theme }) =>
    noPaddingTop ? "0px" : theme.header.height};
`;
