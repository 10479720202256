import { crud } from '@think-internet/zeus-frontend-package'

const props = {
  ...crud.Features,

  // admin features
  CUSTOMER: 'CUSTOMER',
  PROJECT: 'PROJECT',
  SURVEY: 'SURVEY',
  SURVEY_ANSWER: 'SURVEY_ANSWER',
  SURVEY_ANALYSIS: 'SURVEY_ANALYSIS',
  MEMBER: 'MEMBER',
}

export default props
