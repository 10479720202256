import { StyledHero } from "./Hero.Styled";
import logo from "../../../assets/image/generic/logo.svg";
import welcome from "../../../assets/image/home/hero/welcome.svg";
import address from "../../../assets/image/home/hero/address.svg";

const Hero = () => {
  return (
    <StyledHero>
      <div className="flex flex-col gap-20 items-center">
        <img
          src={welcome}
          alt="Wilms-Steuern"
          className="w-[60vw] md:w-[40vw] lg:w-[20vw]"
        />
        <img
          src={logo}
          alt="Wilms-Steuern"
          className="w-[80vw] md:w-[65vw] lg:w-[50vw]"
        />
        <img
          src={address}
          alt="Wilms-Steuern"
          className="w-[60vw] md:w-[40vw] lg:w-[20vw]"
        />
      </div>
    </StyledHero>
  );
};

export default Hero;
