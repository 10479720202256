// import { useState } from "react";
import { Logo, LogoLink } from "./Header.Styled";
import logo from "../../../../assets/image/generic/logo.svg";
// import toggle from "../../../../assets/image/header/toggle.svg";
// import Menu from "./Menu/Menu";
// import { useSelector } from "react-redux";
// import { getUUID } from "../../../../utility";
// import props from "../../../../redux/props";
import Container from "../../Container/Container";

const Header = () => {
  // const translation = useSelector((s) => s[props.TRANSLATION]);
  // const [showMenu, setShowMenu] = useState(false);

  return (
    <div className="fixed w-full z-50 shadow-md bg-header">
      <Container>
        <div className="flex h-[60px] justify-between items-center flex-row relative">
          <LogoLink href="/" className="h-full">
            <Logo src={logo} />
          </LogoLink>
          {/* <div className="hidden md:flex flex-row gap-4">
            {translation.header.items.map((i) => (
              <a
                className="no-underline"
                key={getUUID()}
                href={i.href}
                target={i.newTab ? "_blank" : "_self"}
                rel="noreferrer"
              >
                {i.label}
              </a>
            ))}
          </div>
          <Toggle src={toggle} onClick={() => setShowMenu(!showMenu)} />
          {showMenu && <Menu />} */}
        </div>
      </Container>
    </div>
  );
};

export default Header;
