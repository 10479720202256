import { useSelector } from "react-redux";
import Layout from "../UI/Layout/Layout";
import props from "../../redux/props";
import "../Imprint/legal.scss";
import Container from "../UI/Container/Container";

const DataProtection = () => {
  const t = useSelector((s) => s[props.TRANSLATION]);

  return (
    <Layout>
      <div className="legal">
        <div className="text-center bg-graySecondary text-2xl text-white h-40 flex items-center justify-center">
          {t.dataProtection.headline}
        </div>
        <Container className="mt-10">
          <p className="margin">Sehr geehrte Besucher/innen,</p>
          <p>
            der Schutz Ihrer Privatsphäre ist uns wichtig. Wir sind als Anwälte
            gesetzlich zur Verschwiegenheit verpflichtet. Darüber hinaus gelten
            selbstverständlich auch für uns die gesetzlichen Regelungen zum
            Datenschutz. Mit nachfolgenden Informationen möchten wir Ihnen einen
            Überblick verschaffen, wie wir mit Ihren Daten umgehen, die wir im
            Zusammenhang mit dem Besuch unserer Internetseite erfassen und bei
            der Nutzung unserer anwaltlichen Leistungen bei Ihnen erheben. Zudem
            möchten wir Sie über Ihre Datenschutzrechte informieren. Welche
            Daten im Einzelnen verarbeitet, und wie sie genutzt werden, hängt
            von den jeweils in Anspruch genommenen Leistungen/ dem jeweiligen
            Mandat ab.
          </p>
          <div className="subtitle">
            1. Verantwortlicher, Art. 4 Ziffer 7 DSGVO
          </div>
          <p>
            Der für die Verarbeitung Ihrer Daten Verantwortliche im Sinne von
            Art. 4 Ziffer 7 Datenschutz – Grundverordnung (DSGVO) ist die
          </p>
          <p className="margin">
            WILMS und Partner mbB
            <br />
            Alt-Tempelhof 37/41
            <br />
            12103 Berlin
          </p>
          <p className="margin">
            Weitere Informationen zu uns finden Sie im{" "}
            <a href="/impressum">Impressum</a>.
          </p>
          <div className="subtitle">2. Was sind „Personenbezogene Daten"?</div>
          Personenbezogene Daten sind Informationen zu Ihrer Person, die
          Rückschlüsse auf Ihre Identität zulassen, z.B. Ihr Name, Ihre Adresse
          oder Ihre Telefonnummer. Informationen, die keine Rückschlüsse auf
          eine bestimmte oder bestimmbare Person zulassen, fallen nicht
          darunter.
          <div className="subtitle">
            3. Umfang der Datenerhebung, -verarbeitung und -nutzung
          </div>
          <p className="bold large">a) Auf unserer Internetseite</p>
          <p className="bold">
            aa) Kontaktaufnahme per Mail oder Kontaktformular
          </p>
          <p>
            Bei Ihrer Kontaktaufnahme mit uns per E-Mail oder über ein
            Kontaktformular werden die von Ihnen mitgeteilten Daten (Ihre
            E-Mail-Adresse, bei Rückrufwunsch Ihr Name und Ihre Telefonnummer)
            von uns gespeichert, um Ihre Fragen oder Anliegen zu beantworten
          </p>
          <p className="bold">bb) Besuch unserer Internetseite</p>
          <p>
            Beim rein informatorischen Besuch unserer Internetseite, also wenn
            Sie sich nicht registrieren oder uns anderweitig Informationen
            übermitteln, erheben wir nur die personenbezogenen Daten, die Ihr
            Browser an unseren Server übermittelt. Wenn Sie unsere Website
            betrachten möchten, erheben wir die folgenden Daten, die für uns
            technisch erforderlich sind, um Ihnen unsere Website anzuzeigen und
            die Stabilität und Sicherheit zu gewährleisten:
          </p>
          <ul>
            <li>IP-Adresse (wird anonymisiert)</li>
            <li>Datum und Uhrzeit der Anfrage</li>
            <li>Inhalt der Anforderung (konkrete Seite)</li>
            <li>Website, von der die Anforderung kommt</li>
            <li>Browser</li>
            <li>Betriebssystem und dessen Oberfläche</li>
            <li>Sprache und Version der Browsersoftware</li>
          </ul>
          <p className="bold">cc) Cookies</p>
          <p>
            Auf unseren Internetseiten können so genannte Cookies eingesetzt
            werden. Diese dienen dazu, unser Angebot nutzerfreundlicher,
            effektiver und sicherer zu machen. Cookies sind kleine Textdateien,
            die auf Ihrem Rechner abgelegt werden, und die Ihr Browser
            speichert. Etwaige von uns verwendete Cookies sind so genannte
            „Session-Cookies“. Sie werden nach Ende Ihres Besuchs automatisch
            gelöscht. Cookies richten auf Ihrem Rechner keinen Schaden an und
            enthalten keine Viren.
          </p>
          <p>
            Um den Einsatz von Cookies zu unterbinden, können Sie Ihre
            Browser-Einstellung entsprechend Ihren Wünschen konfigurieren und
            z. B. die Annahme von Third-Party-Cookies oder allen Cookies
            ablehnen. Wir weisen Sie darauf hin, dass Sie eventuell nicht alle
            Funktionen dieser Website nutzen können.
          </p>
          <p className="bold">b) Datenverarbeitung im Rahmen des Mandats</p>
          <p>
            Wenn Sie uns mandatieren wollen, erheben wir Namen, Adresse und
            weitere Kontaktdaten, damit wir mit Ihnen kommunizieren können. Die
            Daten werden bei uns gespeichert und im Rahmen der
            Mandatsbearbeitung genutzt. Je nach Mandat fragen wir weitere Daten
            bei Ihnen ab, die wir für unsere Arbeit brauchen. Hierzu gehören
            beispielsweise Daten zu Ihrer Rechtsschutzversicherung oder anderen
            Versicherungen, aber auch Ihre Bankverbindung, wenn wir Gelder an
            Sie auszahlen sollen.
          </p>
          <div className="subtitle">
            4. Zweck der Verarbeitung und Rechtsgrundlage
          </div>
          <p>
            Wir verarbeiten personenbezogene Daten gemäß den Bestimmungen der
            Europäischen Datenschutzgrundverordnung (DSGVO) und dem
            Bundesdatenschutzgesetz (BDSG).
          </p>
          <p className="bold">
            a) Zur Erfüllung vertraglicher Pflichten (Art. 6 Abs. 1 b) DSGVO)
          </p>
          <p>
            Die Verarbeitung von Daten erfolgt zur Erbringung unserer
            vertraglichen Leistungen im Rahmen eines Mandats oder zur
            Durchführung vorvertraglicher Maßnahmen, die auf Ihre Anfrage hin
            erfolgen. Der Zweck der Maßnahme richtet sich vorrangig nach dem
            konkreten Inhalt des Mandats bzw. Ihrer Anfrage.
          </p>
          <p className="bold">
            b) Im Rahmen der Interessenabwägung (Art. 6 Abs. 1 f) DSGVO)
          </p>
          <p>
            Über die Nutzung zur eigentlichen Vertragserfüllung hinaus kann es
            erforderlich sein, Ihre Daten zur Wahrung berechtigter Interessen
            von uns oder Dritten zu verarbeiten, z.B.
          </p>
          <ul>
            <li>Analyse der Nutzung unserer Internetseite</li>
            <li>
              Erstellung von Zugriffsstatistiken ohne jeden Personenbezug und zu
              Zwecken der Identifikation und Nachverfolgung unzulässiger
              Zugriffsversuche und Zugriffe auf den Web-Server durch eigens
              hierzu berechtigte Personen
            </li>
            <li>Gewährleistung der IT-Sicherheit unseres Systems</li>
            <li>
              Geltendmachung eigener rechtlicher Ansprüche und Verteidigung bei
              rechtlichen Streitigkeiten
            </li>
          </ul>
          <p className="bold">
            c) Aufgrund Ihrer Einwilligung (Art. 6 Abs. 1 a) DSGVO)
          </p>
          <p>
            Für bestimmte Zwecke (z.B. Übermittlung von Daten an Dritte und/oder
            in Drittstaaten) benötigen wir Ihre Einwilligung. Eine Verarbeitung
            findet insoweit nur statt, wenn Sie diese Einwilligung ausdrücklich
            erteilt haben. Sie können Ihre Einwilligung jederzeit widerrufen.
            Der Widerruf berührt nicht die Rechtmäßigkeit der Verarbeitung vor
            Zugang des Widerrufs.
          </p>
          <div className="subtitle">5. Empfänger der Daten</div>
          <ul>
            <li>
              Daten, die wir auf unserer Internetseite in Server Log-Files
              erfassen, werden nicht an Dritte übermittelt.
            </li>
            <li>
              Empfänger Ihrer Daten können andere Rechtsanwälte als
              Unterbeauftragte sein, die in Einzelfällen und nach Absprache mit
              Ihnen Gerichtstermine oder andere Termine wahrnehmen.
            </li>
            <li>
              Wenn die Kosten unserer anwaltlichen Leistungen über eine
              Rechtschutzversicherung abgerechnet werden sollen, übermitteln wir
              personenbezogene Daten an Ihren Versicherer (z.B. im Rahmen der
              Deckungsanfrage).
            </li>
            <li>
              Wenn Sie uns mandatieren, übermitteln wir personenbezogene Daten
              im Rahmen des Mandats an Gerichte, Behörden, Versicherungen,
              Sachverständige und andere Verfahrensbeteiligte.
            </li>
          </ul>
          <div className="subtitle">
            6. Übermittlung von Daten in ein Drittland
          </div>
          <p>
            An Empfänger mit Sitz außerhalb der EU und des EWR übermitteln wir
            Daten nur mit Ihrer ausdrücklichen Einwilligung oder soweit dies
            gesetzlich vorgeschrieben ist. Eine Übermittlung in Drittländer kann
            insbesondere dann erforderlich sein, wenn Gerichte, Behörden oder
            andere Verfahrensbeteiligte ihren Sitz in einem Drittland haben.
            Eine Übermittlung erfolgt aber auch in diesem Fall nur, soweit dies
            nach Art. 44 ff. DSGVO erlaubt ist.
          </p>
          <div className="subtitle">7. Dauer der Speicherung</div>
          <p>
            Wir speichern Ihre Daten grundsätzlich nur so lange, wie die
            Speicherung für die Erfüllung vertraglicher und gesetzlicher
            Pflichten erforderlich ist. Sind die Daten hierfür nicht mehr
            erforderlich, werden sie regelmäßig gelöscht.
          </p>
          <p>
            Protokolldaten unserer Internetseite (Server-Logfiles) werden
            beispielsweise für die Dauer von 30 Tagengespeichert und dann
            automatisch gelöscht.
          </p>
          <p>
            Daten, die Sie uns im Rahmen von Anfragen zukommen lassen, verwenden
            wir zur Beantwortung Ihrer Anfrage und speichern sie für 30 Tage, um
            Rückfragen beantworten zu können. Erfolgt keine weitere Reaktion,
            dann werden die Daten gelöscht.
          </p>
          <p>
            Daten, die in Session Cookies erfasst werden, werden mit Schließen
            des Browsers gelöscht.
          </p>
          <p>Eine Löschung von Daten erfolgt nicht, soweit und solange</p>
          <ul>
            <li>
              Handels- und steuerrechtliche Aufbewahrungspflichten
              entgegenstehen,
            </li>
            <li>
              Daten zum Nachweis von Forderungen aus dem Vertragsverhältnis
              erhalten bleiben müssen, im Rahmen der regelmäßigen
              Verjährungsfrist (3 Jahre, § 195 BGB).
            </li>
            <li>
              Soweit eine Löschung nach vorstehendem Satz nicht erfolgt, werden
              die Daten gesperrt.
            </li>
          </ul>
          <p>
            Die Erforderlichkeit der Datenspeicherung wird in regelmäßigen
            Abständen überprüft, mindestens aber alle 2 Jahre.
          </p>
          <div className="subtitle">8. Sicherheit Ihrer Daten</div>
          <p>
            Wir treffen alle angemessenen technisch-organisatorische Maßnahmen,
            um den Schutz Ihrer Daten zu gewährleisten. Dritte, die in unserem
            Auftrag Ihre Daten verarbeiten oder mit diesen in Kontakt kommen
            können, werden entsprechend den gesetzlichen Vorgaben schriftlich
            zur Einhaltung der datenschutzrechtlichen Vorschriften verpflichtet.
            Unsere Mitarbeiter sind alle auf das Datengeheimnis verpflichtet.
          </p>
          <div className="subtitle">9. Ihre Datenschutzrechte</div>
          <p>Als „betroffene Person“ haben Sie das Recht auf</p>
          <ul>
            <li>Auskunft nach Art. 15 DSGVO</li>
            <li>Berichtigung nach Art. 16 DSGVO</li>
            <li>Löschung nach Art. 17 DSGVO</li>
            <li>Einschränkung der Verarbeitung nach Art. 18 DSGVO</li>
            <li>
              Widerspruch aus Art. 21 DSGVO gegen die Verarbeitung nach Art. 6
              Abs. 1 f) DSGVO sowie
            </li>
            <li>Datenübertragbarkeit aus Art. 20 DSGVO.</li>
          </ul>
          <p>
            Beim Auskunftsrecht und beim Löschungsrecht gelten die
            Einschränkungen nach §§ 34 und 35 BDSG (n.F.). Sie haben auch das
            Recht, sich bei der zuständigen Datenschutzbehörde zu beschweren
            (Art. 77 DSGVO i.V. m. § 19 BDSG (n.F.)).
          </p>
          <div className="subtitle">
            10. Pflicht zur Bereitstellung von Daten
          </div>
          <p>
            Sie sind gesetzlich nicht verpflichtet, uns Daten zur Verfügung zu
            stellen.
          </p>
          <p>
            Für die Inanspruchnahme unserer Leistungen ist es jedoch
            erforderlich, dass Sie uns einige Daten mitteilen, die für die
            Erbringung der jeweiligen Leistung benötigt werden. Wenn Sie
            beispielsweise Kontakt zu uns aufnehmen, brauchen wir eine
            Mailadresse oder eine Telefonnummer, wenn Sie zurückgerufen werden
            möchten. Andernfalls können wir Ihre Anfrage nicht beantworten.
          </p>
          <p>
            Wenn Sie uns mandatieren möchten, benötigen wir Ihren Namen, Ihre
            Adresse und weitere Kontaktdaten (wie Telefonnummer oder
            Mailadresse), damit wir mit Ihnen korrespondieren können. Sollen wir
            Geld für Sie in Empfang nehmen, dann müssen Sie uns Ihre Bankdaten
            mitteilen. Im Rahmen der Mandatsbearbeitung kann es – abhängig vom
            jeweiligen Mandat – erforderlich sein, dass Sie uns auch besondere
            Kategorien von Daten (z.B. Gesundheitsdaten) überlassen müssen,
            damit wir Ihre Interessen vertreten können.
          </p>
          <p>
            Ohne die Zurverfügungstellung von Daten ist eine Nutzung unserer
            Leistungen nicht möglich und können wir keine Leistungen erbringen
            bzw. kein Mandatsverhältnis mit Ihnen eingehen.
          </p>
          <div className="subtitle">
            11. Automatisierte Entscheidungsfindung und Profiling
          </div>
          <p>
            Eine Automatisierte Entscheidungsfindung und/oder Profiling im Sinne
            von Art. 22 Abs. 1 und 4 DSGVO finden nicht statt.
          </p>
          <div className="subtitle">12. Datenschutzbeauftragter</div>
          <p>
            Wenn Sie Fragen oder Anmerkungen zum Thema Datenschutz haben, senden
            Sie uns eine E-Mail an datenschutz(at)wilms.eu.
          </p>
          <div className="subtitle">
            13. Änderungen der Datenschutzerklärung
          </div>
          <p>
            Wir behalten uns vor, die Regelungen unserer Datenschutzerklärung
            von Zeit zu Zeit anzupassen. Ältere Fassungen der
            Datenschutzerklärung können Sie über die o.g. Mailadresse bei uns
            anfordern.
          </p>
          <p>V 3.0 Berlin im Mai 2018</p>
        </Container>
      </div>
    </Layout>
  );
};

export default DataProtection;
