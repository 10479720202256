import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { ThemeProvider } from "styled-components";
import theme from "./theme";
import "./Global.Styled";
import Home from "./components/Home/Home";
// import DataProtection from "./components/DataProtection/DataProtection";
import GlobalStyles from "./Global.Styled";
import Imprint from "./components/Imprint/Imprint";
import DataProtection from "./components/DataProtection/DataProtection";

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Router>
          <Routes>
            {/* home */}
            <Route index element={<Home />} />

            {/* legal */}
            <Route path={"impressum"} element={<Imprint />} />
            <Route path={"datenschutz"} element={<DataProtection />} />

            {/* fallback routing */}
            <Route path={"*"} element={<Navigate replace to="/" />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
