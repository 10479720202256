import Hero from "./Hero/Hero";
import Layout from "../UI/Layout/Layout";

const Home = () => {
  return (
    <Layout noPaddingTop={true}>
      <div>
        <Hero />
      </div>
    </Layout>
  );
};

export default Home;
