import styled from "styled-components";

export const StyledHeader = styled.div`
  position: fixed;
  width: 100%;
  z-index: 1000;
  box-shadow: ${({ theme }) => theme.shadow};
`;

export const LogoLink = styled.a`
  height: calc(100% - 20px);
`;

export const Logo = styled.img`
  height: 100%;
`;

export const Toggle = styled.img`
  height: calc(100% - 20px);
  cursor: pointer;
  display: none;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    display: block;
  }
`;
