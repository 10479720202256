import { useSelector } from "react-redux";
import Layout from "../UI/Layout/Layout";
import props from "../../redux/props";
import "../Imprint/legal.scss";
import Container from "../UI/Container/Container";

const Imprint = () => {
  const t = useSelector((s) => s[props.TRANSLATION]);

  return (
    <Layout>
      <div className="legal">
        <div className="text-center bg-graySecondary text-2xl text-white h-40 flex items-center justify-center">
          {t.imprint.headline}
        </div>
        <Container className="mt-10">
          <div className="title">Angaben gemäß TMG, §§ 2, 3 DL-Info V:</div>
          <div className="subtitle">Diensteanbieter i.S.v. § 5 TMG:</div>
          <p>WILMS und Partner mbB</p>
          <p>vertreten durch:</p>
          <p>Rechtsanwalt Diedrich Wilms</p>
          <p>Rechtsanwalt Friedrich Schulze</p>
          <p>Rechtsanwältin Kathrin Schwartz</p>
          <p>Rechtsanwalt Felix Freist</p>
          <div className="subtitle">Kontaktdaten:</div>
          <p>Alt-Tempelhof 37/41</p>
          <p>12103 Berlin</p>
          <p>Tel.: +49 30 75 79 64 44</p>
          <p>Fax: +49 30 75 79 64 48</p>
          <p>
            E-Mail:{" "}
            <a href="mailto:rae@wilms.eu" target="_blank" rel="noreferrer">
              rae(at)wilms.eu
            </a>
          </p>
          <p>
            Website:{" "}
            <a
              href="https://wilms-steuern.de/"
              target="_blank"
              rel="noreferrer"
            >
              wilms-steuern.de
            </a>
          </p>
          <div className="subtitle">Inhaltlich Verantwortlicher:</div>
          <p className="margin">
            Rechtsanwalt Diedrich Wilms, Alt-Tempelhof 37/41, 12103 Berlin
          </p>
          <p className="margin">
            Die Rechtsanwältinnen und Rechtsanwälte von WILMS Rechtsanwälte sind
            Mitglieder der Rechts- anwaltskammer Berlin, Littenstraße 9 10179
            Berlin. Die Berufsbezeichnung „Rechtsan- walt“/“Rechtsanwältin“
            wurde in der Bundesrepublik Deutschland verliehen.
          </p>
          <p>Für unsere anwaltliche Tätigkeit gelten</p>
          <p>- die Bundesrechtsanwaltsordnung (BRAO),</p>
          <p>- das Rechtsanwaltsvergütungsgesetz (RVG)</p>
          <p>- die Berufsordnung für Rechtsanwälte (BORA) sowie</p>
          <p>- die Fachanwaltsordnung (FAO).</p>
          <p className="margin">
            Die aktuellen berufsrechtlichen Regelungen sind auf der Website der{" "}
            <a
              href="https://www.rak-berlin.de/das-recht/berufsrecht/berufsrecht.php"
              target="_blank"
              rel="noreferrer"
            >
              Rechtsanwaltskammer Berlin
            </a>{" "}
            abrufbar.
          </p>

          <p className="margin">
            Berufshaftpflichtversicherung:
            <br />
            Allianz SE, Königinstraße 28, 80802 München, Räumlicher
            Geltungsbereich: Europa
          </p>

          <p className="margin">
            Plattform der EU-Kommission zur Online-Streitbeilegung:{" "}
            <a
              href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage"
              target="_blank"
              rel="noreferrer"
            >
              www.ec.europa.eu/consumers/odr
            </a>{" "}
            Wir nehmen an Streitbeilegungsverfahren vor einer
            Verbraucherschlichtungsstelle nicht teil und sind hierzu auch nicht
            gesetzlich verpflichtet.
          </p>

          <p className="margin">
            WILMS und Partner mbB ist eine Partnerschaftsgesellschaft mit
            beschränkter Berufshaftung nach Maßgabe der §§ 4 Abs. 3, 8 Abs. 4
            des Gesetzes über Partnerschaftsgesellschaften Angehöriger freier
            Berufe (Partnerschaftsgesellschaftsgesetz – PartGG) vom 25.07.1994
            (BGBl I. S. 1744), geändert durch das Gesetz zur Einführung einer
            Partnerschaftsgesellschaft mit beschränkter Berufshaftung und zur
            Änderung des Berufsrechts der Rechtsanwälte, Patentanwälte,
            Steuerberater und Wirtschaftsprüfer vom 15.07.2013 (BGBl I. S.
            2386). Die Partnerschaft hat ihren Sitz in Berlin und ist im
            Partnerschaftsregister des Amtsgerichts Charlottenburg (PR 1789 B)
            eingetragen.
          </p>
        </Container>
      </div>
    </Layout>
  );
};

export default Imprint;
