import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import { CoreContent, StyledLayout } from "./Layout.Styled";

type Props = {
  children: React.ReactNode;
  className?: string;
  hideFooter?: boolean;
  hideHeader?: boolean;
  noPaddingTop?: boolean;
};

const Layout: React.FC<Props> = ({
  children,
  className,
  hideFooter = false,
  hideHeader = false,
  noPaddingTop = false,
}) => {
  return (
    <StyledLayout className={className}>
      {!hideHeader && <Header />}
      <CoreContent noPaddingTop={noPaddingTop}>{children}</CoreContent>
      {!hideFooter && <Footer />}
    </StyledLayout>
  );
};

export default Layout;
